<template>
  <auth-layout>
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-card
        flat
        :max-width="500"
        :width="500"
        :loading="loading"
        color="background"
      >
        <div class="pa-6">
          <v-card-title class="texPrimary--text text-h5 font-weight-semibold">{{
            $t('auth.resetPassword')
          }}</v-card-title>
          <div v-if="resetPasswordSuccess">
            <v-card-text>
              <div class="text-pre-wrap font-weight-medium text-body-1">
                {{ $t('auth.resetPasswordSuccessMessage') }}
              </div>
            </v-card-text>
            <v-card-actions>
              <cz-button
                :title="$t('auth.backToLogin')"
                color="primary"
                x-large
                type="submit"
                :loading="loading"
                :disabled="invalid"
                class="full-width font-weight-semibold"
                @click="
                  $router.replace({
                    name: 'login'
                  })
                "
              />
            </v-card-actions>
          </div>
          <v-form @submit.prevent="onSubmit" v-else>
            <v-card-text>
              <cz-form-field large required :label="$t('auth.password')">
                <validation-provider
                  rules="required|password|confirmed:confirmation"
                  :name="$t('auth.password')"
                  v-slot="{ errors }"
                >
                  <cz-input
                    v-model="password"
                    :error-messages="errors"
                    type="password"
                  />
                </validation-provider>
              </cz-form-field>
              <cz-form-field
                large
                required
                :label="$t('auth.cofirmedPassword')"
              >
                <validation-provider
                  vid="confirmation"
                  :name="$t('auth.cofirmedPassword')"
                  v-slot="{ errors }"
                >
                  <cz-input
                    v-model="confirmedPassword"
                    type="password"
                    :error-messages="errors"
                  />
                </validation-provider>
              </cz-form-field>
              <v-fade-transition>
                <v-alert
                  type="error"
                  v-if="errorMessage"
                  class="text-pre-wrap"
                  >{{ errorMessage }}</v-alert
                >
              </v-fade-transition>
            </v-card-text>
            <v-card-actions>
              <div class="full-width d-flex align-center flex-column">
                <cz-button
                  :title="$t('auth.resetPasswordButton')"
                  color="primary"
                  x-large
                  type="submit"
                  :loading="loading"
                  :disabled="invalid"
                  class="full-width font-weight-semibold"
                />
              </div>
            </v-card-actions>
          </v-form>
        </div>
      </v-card>
    </validation-observer>
  </auth-layout>
</template>

<script>
import bg from '@/assets/background-pattern.jpg';
import AuthLayout from '@/layouts/AuthLayout.vue';
import { CzFormField, CzInput, CzButton } from '@/components';
import { mapActions } from 'vuex';
import { resetPassword } from '@/core/api';
export default {
  name: 'ResetPasswordPage',
  components: {
    AuthLayout,
    CzFormField,
    CzInput,
    CzButton
  },
  data() {
    return {
      bg,
      loading: false,
      password: '',
      confirmedPassword: '',
      resetPasswordSuccess: false,
      token: '',
      errorMessage: ''
    };
  },
  methods: {
    ...mapActions('auth', ['login']),
    async onSubmit() {
      try {
        this.loading = true;

        await resetPassword(this.token, this.password);
        this.resetPasswordSuccess = true;
      } catch (error) {
        this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = '';
        }, 5000);
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(value) {
        // check if the query params contains a token
        // if they are not contain any token then the user is not allowed
        // to change his password and we need to route the user back to the log in page
        if (!value.query?.token) {
          this.$router.replace({
            name: 'login'
          });
        } else {
          this.token = value.query.token;
        }
      }
    }
  }
};
</script>
